<template>
  <v-container class="pa-4 text-center">
    <v-row
        class="fill-height"
        align="center"
        justify="center"
    >
      <v-col>
        <v-sheet class="mx-auto" elevation="0" max-width="800">
          <v-slide-group
            v-model="model"
            class="pa-4"
            show-arrows
          >
            <v-slide-item v-for="n in data" :key="n.id">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="ma-4"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                >
                  <v-img
                    max-width="200"
                    max-height="100"
                    :src="`data:image;base64,${n.thumbnail}`"
                  >
                    <v-row
                      class="fill-height flex-column"
                      justify="center"
                      align="center"
                    >
                      <div class="align-self-center">
                        <v-btn
                          :class="{ 'show-btns': hover }"
                          color="rgba(255, 255, 255, 0)"
                          icon
                          @click="videoFormFunc(true,n.id,n.format)"
                        >
                          <v-icon
                            :class="{ 'show-btns': hover }"
                            color="rgba(255, 255, 255, 0)"
                          >
                            {{icon(n.format)}}
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-row>
                  </v-img>
                </v-card>
              </v-hover>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row>
    <VideoFrame :value="videoForm" :close="onVideoClose" :media="mediaData" :isPassedImage="isImage" :video="videoData"/>
  </v-container>
</template>

<script>
import VideoFrame from '../Complements&Buttons/videoDialog' 
import axios from '../../mixins/axiosInstanceV2'

export default {
  components:{
      VideoFrame
  },
  data() {
    return {
      model: null,
      transparent: "rgba(255, 255, 255, 0)",
      url: '',
      mediaId: 0,
      mediaData: null,
      videoData: null,
      isImage: false,
      videoForm: false,
    };
  },
  methods: {
      icon(type){
          let img_formats = ['png','jpg','jpeg','bmp','tif','tiff','PNG','JPG','JPEG','BMP','TIF','TIFF']
          let video_formats = ['WEBM','MP4','MPG','MP2','MPEG','MPE','MPV','M4P','M4V','AVI','WMV','MOV','FLV','webm','mp4','mpg','mp2','mpeg','mpe','mpv','m4p','m4v','avi','wmv','mov','flv']
          if(img_formats.includes(type)){
              return 'mdi-eye-outline'
          }
          else if(video_formats.includes(type)){
              return 'mdi-play'
          }
      },
      async videoFormFunc(status, id, type){
        try {
          if(this.icon(type) === 'mdi-eye-outline'){
            axios.instance.defaults.headers.common['token'] = localStorage.getItem('token');
            let image = await axios.instance.get('/tickets_media?img_id='+id)
            this.mediaData = image.data.data
            this.isImage = true
            this.videoForm = status
          }else{
            axios.instance.defaults.headers.common['token'] = localStorage.getItem('token')
            let dataString = await axios.instance.get('/tickets_media?img_id='+id)
            this.videoData = dataString.data.data
            this.isImage = false
            this.videoForm = status
          }
        } catch (error) {
          if(error){
            alert('Ocurrio un error al abrir la multimedia')
          }
        }
      },
      onVideoClose(){
        this.videoForm = false
      }
  },
  props: {
      data: Array
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>