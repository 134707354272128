<template>
    <v-btn
        class="mt-2"
        fab
        dark
        small
        color="green"
        @click="Fn"
    >
        <v-icon dark>
            mdi-plus
        </v-icon>
    </v-btn>
</template>
<script>
export default {
    props:{
        Fn: Function //Is used to open thee dialog
    }
}
</script>