const source = () => {
  if (process.env.NODE_ENV === "development") {
    //return 'http://192.168.1.201:5051/api/v2/tickets_media/video?name='
    return "https://www.developcrmback.radiotrunk.com/api/v2/tickets_media/video?name=";
  } else if (process.env.NODE_ENV === "production") {
    return "https://productioncrmback.radiotrunk.com/api/v2/tickets_media/video?name=";
  }
};

export default {
  source,
};
