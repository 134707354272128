<template>
  <div id="app">
      <v-app id="inspire">
      <Navbar/>
      <Table class="mt-3"/>
      
      </v-app>
  </div>
</template>

<script>

import Table from '../components/Tables/TableTickets';
import Navbar from '../components/Navbar';


export default {
    components:{
        Table,
        Navbar,
    },
}
</script>

<style scoped>

</style>