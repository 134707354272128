<template>
    <div v-if="ID">
        <h3>{{Ask}}</h3>
        <v-radio-group :value='Value' row @change="Fn({value:$event,id:ID})">
            <v-radio
                v-for="(option, index) in Options"
                :key="index"
                :label="option.label"
                :value="option.value"
            />
        </v-radio-group>
    </div>
    <div v-else>
        <v-radio-group :value='Value' row @change="Fn($event)">
            <v-radio
                v-for="(option, index) in Options"
                :key="index"
                :label="option.label"
                :value="option.value"
            />
        </v-radio-group>
    </div>
</template>

<script>
export default {
    props:{
        Ask: String,
        Options: Array, //Array of object with the value and the name
        Value: String,
        Fn: Function,
        ID: Number

    }
}
</script>

<style>

</style>