<template>
<v-dialog :value="dialogAddSpare" max-width="610px">
<v-card>
        <v-card-title>
          <span class="headline">Agregar Refaccion</span>
        </v-card-title>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <TextField :Rules="[]" :Value="spareName" Label="Nombre" Type="text" :Fn="updateSpareName"/>
          <TextField :Rules="[]" :Value="sparePartNumber" Label="Numero de parte" Type="text" :Fn="updateSparePartNumber"/>
        </v-col>

      </v-row>
    </v-container>
    <small>*Campos obligatorios</small>
  </v-card-text>

  <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="closeAddSpareDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="green"
            text
            @click="addNewSpareToList()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
</template>
<script>
import TextField from '../../StandardizedComponents/textFields'
import {mapActions, mapMutations, mapState} from 'vuex'
export default {
    name: "addMark",
    components:{
      TextField
    },
    computed:{
      ...mapState('Spares',['dialogAddSpare','spareName','partNumber'])
    },
    methods:{
        ...mapMutations('Spares',['updateSparePartNumber','updateSpareName']),
        ...mapActions('Spares',['addNewSpareToList','closeAddSpareDialog']),
    }
}

</script>