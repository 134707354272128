<template>
    <v-textarea
        :value='Value'
        outlined
        :label="Label"
        counter
        :maxlength="MaxLength"
        @input="Fn($event)"
    />
</template>
<script>
export default {
    props:{
        Value:  String, //Is the Input's value
        Label:  String, //Is the Input's name
        MaxLength:  Number, //The limit of characters can put in the field
        Fn: Function //Is used to change te value of the input
    }
}
</script>