<template>
  
    <v-dialog
      :value="TicketDialog"
      fullscreen
      :persistent="true"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeTicketDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Ticket #{{TicektInformation.ticket_id}}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" >
              <h1 align="center">Datos del ticket</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12"
          md="6">
              <h2>Contacto</h2>
              <h4>Correo Electronico</h4>
              <a :href="'mailto:'+TicektInformation.email">{{TicektInformation.email}}</a> 
              <h4>Telefono</h4>
              <a :href="'tel:'+TicektInformation.phone_number">{{TicektInformation.phone_number}}</a>
              <h4>Observaciones</h4>
              <p>{{TicektInformation.comments}}</p>
            </v-col>
            <v-col cols="12" sm="12"
          md="6">
              <h2>Multimedia</h2>
              <Thumbnails :data="ThumbnailData"/>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container v-if="!canRead()">
          <v-row>
            <v-col cols="12" >
              <h1 align="center">Datos del reporte</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <RadioGroup v-for="(option,index) in row" :ID="option.id" :key="index" :Ask="option.pregunta" :Value="option.estado" :Fn="changeChooser" 
              :Options="[{label:'Si',value:'1'},{label:'No',value:'0'}]"/>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <TextArea :Value="comment" Label="Observaciones" :Fn="updateComment"/>
              <FileInput :Value="arriveImages" PlaceHolder="¿Como llego el radio?" DataType="image/jpeg, image/jpg" :OnChange="updateArriveImages"/>
              <FileInput :Value="returnImages" PlaceHolder="¿Como se fue el radio?" DataType="image/jpeg, image/jpg" :OnChange="updateReturnImages"/>
              <v-container>
                <v-row>
                  <v-col cols="10" sm="5" md="5">
                    <AutoComplete :Rules="[]" :Items="dropdownSpares" :Value="dropdownSpare" Label="Refacciones" :Fn="updateDropdownSpare" :GetValues="getDropdownSpares"/>
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    <ButtonToAdd :Fn="openAddSpareDialog"/>
                  </v-col>
                  <v-col cols="12" sm="5" md="6">
                    <TextField v-if="dropdownSpare" :Value="serialNumberSpare" Label="Numero de serie" Type="text" :Fn="updateSerialNumberSpare"/>
                  </v-col>
                </v-row>
                <AddSpare/>
              </v-container>
              
              <v-btn
                color="success"
                rounded
                @click="addSpare()"

              >
                Agregar refaccion
              </v-btn>
              <v-chip-group
                v-if="sparesSelected.length>0"
                multiple
                active-class="primary--text"
              >
                <v-chip
                  v-for="(Spare ,index) in sparesSelected"
                  color="primary"
                  :key="index"
                  close
                  @click:close="deleteSpare(Spare)"
                >
                  {{ Spare.Spare }}/{{Spare.SN}}
                </v-chip>
              </v-chip-group>
            </v-col>
            
          </v-row>
        </v-container>
        <FAB v-if="!canRead()"
        :buttons="[{
          color: 'primary',
          icon: 'mdi-content-save-settings',
          fn: saveChangesService,
          name: 'Guardar'
        },{
          color: 'red',
          icon: 'mdi-cancel',
          fn: cancelTicket,
          name: 'Cancelar'
        },{
          color: 'green',
          icon: 'mdi-send',
          fn: endTicket,
          name: 'Finalizar'
        }]"/>
      </v-card>
    </v-dialog>

</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import FAB from '../../Complements&Buttons/fab'
import Thumbnails from '../../Complements&Buttons/thumbnails'
import RadioGroup from '../../StandardizedComponents/radioGroup'
import TextArea from '../../StandardizedComponents/textArea'
import TextField from '../../StandardizedComponents/textFields'
import FileInput from '../../StandardizedComponents/fileInput'
import AutoComplete from '../../StandardizedComponents/autocompleteFields'
import ButtonToAdd from '../../StandardizedComponents/buttonToAdd'
import AddSpare from '../SpareDialogs/addSpare'

export default {
  components:{
    Thumbnails,
    TextArea,
    FAB,
    RadioGroup,
    FileInput,
    AutoComplete,
    ButtonToAdd,
    TextField,
    AddSpare
  },
  computed:{
      ...mapState('Tickets',['TicketDialog','TicektInformation','row','ThumbnailData']),
      ...mapState('Services',['row','comment',]),
      ...mapState('ArriveImages',['arriveImages']),
      ...mapState('ReturnImages',['returnImages']),
      ...mapState('Spares',['dropdownSpares','dropdownSpare','serialNumberSpare','sparesSelected'])
  },
  methods:{
      ...mapMutations('ArriveImages',['updateArriveImages']),

      ...mapMutations('ReturnImages',['updateReturnImages']),

      ...mapMutations('Spares',['updateDropdownSpare','updateSerialNumberSpare']),
      ...mapActions('Spares',['getDropdownSpares','openAddSpareDialog','addSpare','deleteSpare']),

      ...mapMutations('Tickets',['openTicketDialog']),
      ...mapActions('Tickets',['endTicket','cancelTicket','closeTicketDialog']),

      ...mapActions('Services',['saveChangesService']),
      ...mapMutations('Services',['changeChooser','updateComment']),
      canRead()
      {
        if(this.TicektInformation.status==='Resuelto'||this.TicektInformation.status==='Cancelado')
        {
          return true
        }
        return false
      },
  },

};
</script>