<template>
  <v-dialog
    :value="value"
    @click:outside="close();stopPlaying()"
    persistent
    width="700" 
    height="390"
  >
    <v-img v-if="isPassedImage" :data-zoom="`data:image;base64,${media}`" class="demo-trigger" :src="`data:image;base64,${media}`"></v-img>
    
    <video v-else ref="video" :src="`${getBaseURL()}${video}`" width="720" height="394" controls autoplay></video>
    <section v-if="isPassedImage" class="detail" style="width: 500px; height: 200px; position: fixed; top:0px; left: 0">
      
    </section>
  </v-dialog>
</template>

<script>
import baseurl from '../../mixins/videoSource'
import Drift from 'drift-zoom';
export default {
    props:{
        value: Boolean,
        close: Function,
        media: String,
        isPassedImage: Boolean,
        video: String
    },
    data(){
      return{
        url: null,
      }
    },
    methods: {
      getBaseURL(){
        return baseurl.source()
      },
      stopPlaying(){
        this.$refs.video.pause()
      }
    },
    updated(){
      var demoTrigger = document.querySelector('.demo-trigger');
      var paneContainer = document.querySelector('.detail');

      new Drift(demoTrigger, {
        paneContainer: paneContainer,
      });
    }
}
</script>

<style scoped>





</style>