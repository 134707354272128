<template>
  <div id="app">
    <v-app id="create">
      <v-container >
        <v-row>
          <v-col cols="12" >
            <v-data-table
              :headers="headers"
              :items="tickets"
              item-key="ticket_id"
              :loading="loading"
              no-data-text="No data found"
              loading-text="Cargando..."
              :search="search"
              @dblclick:row="(event,data)=>openTicketDialog(data.item)"
            >
            
             <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                      <v-spacer></v-spacer>
                      <SearchBar :setSearchTerm="setSearchterm"/>              
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:item.status="{ item }">
                <div>
                  <v-chip
                    :color="getColorChip(item.status)"
                    :text-color="getColorText(item.status)"
                  >
                    {{item.status}}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.username="{item}">
                  <v-hover v-slot="{hover}">
                    <div class="d-flex">
                      <span>{{ item.username }}</span>
                      <v-btn :class="{ 'show-btns': hover }" icon color="rgba(255, 255, 255, 0)" @click="copyToClipboard(item.username)">
                        <v-icon small :class="{ 'show-btns': hover }" color="rgba(255, 255, 255, 0)">mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-hover>
              </template>
              <template v-slot:item.account="{item}">
                  <v-hover v-slot="{hover}">
                    <div class="d-flex">
                      <span>{{ item.account }}</span>
                      <v-btn :class="{ 'show-btns': hover }" icon color="rgba(255, 255, 255, 0)" @click="copyToClipboard(item.account)">
                        <v-icon small :class="{ 'show-btns': hover }" color="rgba(255, 255, 255, 0)">mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </v-hover>
              </template>
              <template  v-slot:item.init_date="{ item }">
                <span v-if="item.init_date">{{item.init_date.substr(0,10)}}</span>
                
              </template>
              <template  v-slot:item.end_date="{ item }">
                <span v-if="item.end_date">{{item.end_date.substr(0,10)}}</span>
              </template>
              <template v-slot:no-data>
                No registers detected
              </template>
            </v-data-table>
            <TicketDialog/>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import SearchBar from '../Complements&Buttons/searchBar'
import TicketDialog from '../Dialogs/TicketsDialogs/ticketInformation'
import { copyToClipboard } from '../../mixins/copyToClipboard';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
   created() {
    this.getAllTickets();
  },
  components:{
    SearchBar,
    TicketDialog
  },
  computed: {
    ...mapState("Tickets",["headers","tickets","loading","search","Info"])
    
  },
  methods: {
    copyToClipboard,
    ...mapMutations("Tickets",['setSearchterm']),
    ...mapActions('Tickets',["openTicketDialog",'generateService','getAllTickets','getServiceInfo','getThumbnails']),
    getColorText(status){
      if(status==="En Revision")
      {
        return "black"
      }
      return "white"
    },
    getColorChip(status){
      switch(status){
        case "Generado":
          return "blue";
        case "En Revision":
        return "yellow";
        case "Resuelto":
          return "green";
        case "Cancelado":
          return "red";
      }
    },
    isAdmin(){
      if(localStorage('isAdmin') === 1)
        return true
      else
        return false
    }
  },
 
  data() {
    return {
      expanded: [],
      textAreaValue: "",
      fab: false,
    };
  },
};
</script>
<style scoped>
.show-btns {
  color: black !important;
}
</style>