var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"create"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tickets,"item-key":"ticket_id","loading":_vm.loading,"no-data-text":"No data found","loading-text":"Cargando...","search":_vm.search},on:{"dblclick:row":function (event,data){ return _vm.openTicketDialog(data.item); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-spacer'),_c('SearchBar',{attrs:{"setSearchTerm":_vm.setSearchterm}})],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":_vm.getColorChip(item.status),"text-color":_vm.getColorText(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(item.username))]),_c('v-btn',{class:{ 'show-btns': hover },attrs:{"icon":"","color":"rgba(255, 255, 255, 0)"},on:{"click":function($event){return _vm.copyToClipboard(item.username)}}},[_c('v-icon',{class:{ 'show-btns': hover },attrs:{"small":"","color":"rgba(255, 255, 255, 0)"}},[_vm._v("mdi-content-copy")])],1)],1)]}}],null,true)})]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(item.account))]),_c('v-btn',{class:{ 'show-btns': hover },attrs:{"icon":"","color":"rgba(255, 255, 255, 0)"},on:{"click":function($event){return _vm.copyToClipboard(item.account)}}},[_c('v-icon',{class:{ 'show-btns': hover },attrs:{"small":"","color":"rgba(255, 255, 255, 0)"}},[_vm._v("mdi-content-copy")])],1)],1)]}}],null,true)})]}},{key:"item.init_date",fn:function(ref){
var item = ref.item;
return [(item.init_date)?_c('span',[_vm._v(_vm._s(item.init_date.substr(0,10)))]):_vm._e()]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [(item.end_date)?_c('span',[_vm._v(_vm._s(item.end_date.substr(0,10)))]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" No registers detected ")]},proxy:true}])}),_c('TicketDialog')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }