<template>
    <v-file-input
        :value="Value"
        color="indigo darken-4"
        counter
        multiple
        :placeholder="PlaceHolder"
        prepend-icon="mdi-plus"
        :accept="DataType"
        outlined
        :show-size="1000"
        @change="OnChange($event)"
    >
        <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="indigo darken-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="overline grey--text text--darken-3 mx-2"
            >
              +{{ Value.length - 2 }} File(s)
            </span>
        </template>
    </v-file-input>   
</template>

<script>
export default {
    props:{
        Value: Array,
        PlaceHolder: String,
        DataType: String,
        OnChange: Function
    }
}
</script>

<style>

</style>